import { Icon } from 'components/images';
import Image from 'next/image';
import router from 'next/router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import Cookies from 'universal-cookie';
import { getDefaultProduct } from 'utils/getDefaultProduct';

export const CustomError = ({ errorType }: {errorType: number;}) => {
  const { t } = useTranslation('etc');
  const [lang] = useAtom(langAtom);
  const cookies = new Cookies();
  const token = cookies.get('token');

  interface ErrorText {
    heading: string;
    desc: string;
  }
  const textObject: Record<number, ErrorText> = {
    404: {
      heading: t('error.500-heading'),
      desc: t('error.500-desc'),
    },
    500: {
      heading: t('error.404-heading'),
      desc: t('error.404-desc'),
    }
  };
  const { heading, desc } = textObject[errorType];
  const goToEngramBtnText = t('error.goToEngramBtn');

  const handleRouter = () => {
    if (token) {
      router.push(getDefaultProduct());
    } else {
      const redirectLandingTarget = lang === 'en' ? '' : lang;
      router.push(`/${redirectLandingTarget}`);
    }
  };
  return (
    <div className="min-h-[100vh] h-fit flex flex-col justify-center items-center bg-background">
      {errorType === 404 ? (
        <div className="w-[78px] h-[88px] md:w-[142px] md:h-[160px] lg:w-[158px] lg:h-[176px] relative object-contain">
          <Image src="/images/error/ill-404@3x.png" alt="the face with 404" fill />
        </div>
      ) : (
        <div className="w-[218px] h-[88px] md:w-[396px] md:h-[160px] lg:w-[436px] lg:h-[176px] relative object-contain">
          <Image src="/images/error/ill-faces@3x.png" alt="Three faces are frowning" draggable={false} fill />
        </div>
      )}
      <h1
        dangerouslySetInnerHTML={{ __html: heading }}
        className="mt-[24px] text-headerM text-center
        md:mt-[48px] md:text-headerXL
        lg:mt-[56px] lg:text-header3XL
        "
      />
      <h2
        dangerouslySetInnerHTML={{ __html: desc }}
        className="mt-[16px] mx-0 mb-[24px] text-subheaderS text-darkGray text-center
        md:mb-[32px] md:text-subheaderL
        lg:mt-[32px] lg:mb-[40px] lg:text-subheader2XL
        "
      />
      <div
        onClick={handleRouter}
        className="flex justify-center items-center cursor-pointer text-subheaderS
        md:text-subheaderM lg:text-subheaderXL"
      >
        <Icon size={32} tbSize={24} mbSize={24} src="/icons/outline/i_left.svg" />
        <span className="ml-0 lg:ml-[4px]">{goToEngramBtnText}</span>
      </div>
    </div>
  );
};

export const Custom404Error = () => {
};
